import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './pages/Home';
import Pick from './pages/Pick';
import Setup from './pages/Setup';
import { SetupProvider } from './setupContext';
import { disableScroll } from './disableScroll';

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <App />,
    errorElement: <div>404 Not Found</div>,
  },
  {
    id: 'home',
    path: '/home',
    element: <Home />,
  },
  {
    id: 'pick',
    path: '/pick',
    element: (
      <SetupProvider>
        <Pick />
      </SetupProvider>
    ),
  },
  {
    id: 'setup',
    path: '/setup',
    element: (
      <SetupProvider>
        <Setup />
      </SetupProvider>
    ),
  },
]);

disableScroll();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
