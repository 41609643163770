import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

const setup = localStorage.getItem('setup');
type Setup = {
  welcomeGoods: number;
  specialGoods: number;
  seasonGoods: number;
  seasonGoodsProbability: number;
  specialGoodsProbability: number;
};
let initialSetup: Setup = {
  welcomeGoods: 100,
  specialGoods: 10,
  seasonGoods: 0,
  specialGoodsProbability: 0.1,
  seasonGoodsProbability: 0,
};
if (setup === null) {
  localStorage.setItem('setup', JSON.stringify(initialSetup));
} else {
  initialSetup = JSON.parse(setup);
}
const SetupContext = createContext<[Setup, Dispatch<SetStateAction<Setup>>] | undefined>(undefined);

export function SetupProvider({ children }: { children: React.ReactNode }) {
  const setup = JSON.parse(localStorage.getItem('setup')!);
  const setupState = useState(setup);
  return <SetupContext.Provider value={setupState}>{children}</SetupContext.Provider>;
}

export const useSetupContext = () => {
  const context = useContext(SetupContext);
  if (context === undefined) {
    throw new Error('useSetupContext must be used within a SetupProvider');
  }
  const [setup, setSetup] = context;
  const setSetupWithLocalStorage = (value: Setup) => {
    localStorage.setItem('setup', JSON.stringify(value));
    setSetup(value);
  };
  const setWelcomeGoods = (value: number) => {
    if (typeof value !== 'number') {
      alert('숫자를 입력해주세요.');
      return;
    }
    setSetupWithLocalStorage({ ...setup, welcomeGoods: value });
  }
  const setSpecialGoods = (value: number) => {
    if (typeof value !== 'number') {
      alert('숫자를 입력해주세요.');
      return;
    }
    setSetupWithLocalStorage({ ...setup, specialGoods: value });
  }
  const setSeasonGoods = (value: number) => {
    if (typeof value !== 'number') {
      alert('숫자를 입력해주세요.');
      return;
    }
    setSetupWithLocalStorage({ ...setup, seasonGoods: value });
  }
  const setSpecialGoodsProbability = (value: number) => {
    if (typeof value !== 'number') {
      alert('숫자를 입력해주세요.');
      return;
    }
    if (value < 0 || value > 1) {
      alert('0 ~ 1 사이의 숫자를 입력해주세요.');
      return;
    }
    setSetupWithLocalStorage({ ...setup, specialGoodsProbability: value });
  }
  const setSeasonGoodsProbability = (value: number) => {
    if (typeof value !== 'number') {
      alert('숫자를 입력해주세요.');
      return;
    }
    if (value < 0 || value > 1) {
      alert('0 ~ 1 사이의 숫자를 입력해주세요.');
      return;
    }
    setSetupWithLocalStorage({ ...setup, seasonGoodsProbability: value });
  }

  return {
    setup,
    setWelcomeGoods,
    setSpecialGoods,
    setSpecialGoodsProbability,
    setSeasonGoods,
    setSeasonGoodsProbability,
  }
};
