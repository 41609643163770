import React, { useEffect } from 'react';
import background from '../assets/bg.jpg';
import logo_intel from '../assets/logo_intel.png';
import logo_samsung from '../assets/logo_samsung.png';
import snowball from '../assets/pick/snowball.png';
import welcome_goods from '../assets/pick/welcome_goods.png';
import special_goods from '../assets/pick/special_goods.png';
import season_goods from '../assets/pick/season_goods.png';

const imageLoadPromise = (src: string) => {
  return new Promise<void>((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve();
    };
  });
};

function PageWithHeader({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const preLoad = async () => {
      await Promise.all([snowball, welcome_goods, special_goods, season_goods].map(imageLoadPromise));
    };
    preLoad();
  }, []);
  return (
    <div className="w-screen h-screen relative bg-cover" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex justify-between items-end px-12" style={{ height: '7.4%' }}>
        <img src={logo_intel} alt="intel" style={{ width: '3.4%' }} />
        <img src={logo_samsung} alt="samsung" style={{ width: '6.5%' }} />
      </div>
      {children}
    </div>
  );
}

export default PageWithHeader;
