import React from 'react';
import title from '../assets/home/title.png';
import btn from '../assets/home/btn.png';
import { useNavigate } from 'react-router-dom';
import PageWithHeader from '../components/PageWithHeader';

function Home() {
  const navigate = useNavigate();
  return (
    <PageWithHeader>
      <div
        className="absolute w-full flex flex-col items-center"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <img src={title} alt="touch the real title" className="w-1/3" />
        <img
          src={btn}
          alt="touch draw button"
          className="w-2/5 mt-5 cursor-pointer"
          onClick={() => navigate('/pick')}
        />
      </div>
    </PageWithHeader>
  );
}

export default Home;
