import React, { useCallback, useState } from 'react';
import './pick.css';
import snowball from '../assets/pick/snowball.png';
import welcome_goods from '../assets/pick/welcome_goods.png';
import special_goods from '../assets/pick/special_goods.png';
import season_goods from '../assets/pick/season_goods.png';
import { useNavigate } from 'react-router-dom';
import { useSetupContext } from '../setupContext';
import PageWithHeader from '../components/PageWithHeader';

function Pick() {
  const navigate = useNavigate();
  const [prize, setPrize] = useState<'welcome' | 'special' | 'season' | null>(null);
  const [isAnimationEnd, setIsAnimationEnd] = useState(false);

  const { setup, setSpecialGoods, setWelcomeGoods, setSeasonGoods } = useSetupContext();

  const draw = useCallback(() => {
    const probability = Math.random();
    if (setup.seasonGoods > 0) {
      if (probability <= setup.seasonGoodsProbability) {
        setSeasonGoods(setup.seasonGoods - 1);
        setPrize('season');
        return;
      }
      if (probability <= setup.specialGoodsProbability + setup.seasonGoodsProbability && setup.specialGoods > 0) {
        setSpecialGoods(setup.specialGoods - 1);
        setPrize('special');
        return;
      }
    } else {
      if (probability <= setup.specialGoodsProbability && setup.specialGoods > 0) {
        setSpecialGoods(setup.specialGoods - 1);
        setPrize('special');
        return;
      }
    }
    setWelcomeGoods(setup.welcomeGoods - 1);
    setPrize('welcome');
  }, [setup, setSpecialGoods, setWelcomeGoods, setSeasonGoods]);
  const takePrize = useCallback(() => {
    if (!isAnimationEnd) return;
    setPrize(null);
    navigate('/home');
  }, [navigate, isAnimationEnd]);

  const onAnimationEnd = useCallback(() => {
    setIsAnimationEnd(true);
  }, [setIsAnimationEnd]);

  return (
    <PageWithHeader>
      <div
        className="absolute w-1/2 flex flex-col items-center"
        style={{ bottom: 0, left: '50%', transform: 'translateX(-50%)' }}
      >
        <img src={snowball} alt="snowball" />
        <div
          className="cursor-pointer absolute w-2/3 h-1/2"
          onClick={draw}
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
      </div>
      {prize !== null && (
        <div
          className="absolute top-0 left-0 w-full h-full z-10 flex justify-center items-center pt-16"
          style={{ backgroundColor: '#000a' }}
        >
          <div className="w-2/5 relative">
            {prize === 'welcome' ? (
              <img src={welcome_goods} alt="welcome goods" className="goods" onAnimationEnd={onAnimationEnd} />
            ) : prize === 'special' ? (
              <img src={special_goods} alt="special goods" className="goods" onAnimationEnd={onAnimationEnd} />
            ) : (
              <img src={season_goods} alt="season goods" className="goods" onAnimationEnd={onAnimationEnd} />
            )}
            <div
              style={{
                width: '8%',
                height: '20%',
                right: 0,
                top: 0,
                transform: 'translate(10%, -10%)',
              }}
              className="absolute"
              onClick={takePrize}
            />
          </div>
        </div>
      )}
    </PageWithHeader>
  );
}

export default Pick;
