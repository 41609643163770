import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetupContext } from '../setupContext';
import PageWithHeader from '../components/PageWithHeader';

function Setup() {
  const navigate = useNavigate();
  const { setup, setWelcomeGoods, setSpecialGoods, setSeasonGoods, setSpecialGoodsProbability, setSeasonGoodsProbability } = useSetupContext();
  return (
    <PageWithHeader>
      <div
        className="absolute w-1/2 flex flex-col gap-3"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <div className="flex gap-2">
          <label className="text-white text-2xl w-80">남은 웰컴 Goods 수량</label>
          <input
            className="p-2 rounded w-16"
            type="number"
            value={setup.welcomeGoods}
            onChange={(e) => setWelcomeGoods(Number(e.target.value))}
          />
        </div>
        <div className="flex gap-2">
          <label className="text-white text-2xl w-80">남은 스페셜 Goods 수량</label>
          <input
            className="p-2 rounded w-16"
            type="number"
            value={setup.specialGoods}
            onChange={(e) => setSpecialGoods(Number(e.target.value))}
          />
        </div>
        <div className="flex gap-2">
          <label className="text-white text-2xl w-80">남은 시즌 Goods 수량</label>
          <input
            className="p-2 rounded w-16"
            type="number"
            value={setup.seasonGoods}
            onChange={(e) => setSeasonGoods(Number(e.target.value))}
          />
        </div>
        <div className="flex gap-2">
          <label className="text-white text-2xl w-80">스페셜 Goods 확률(0 ~ 1)</label>
          <input
            className="p-2 rounded w-16"
            type="number"
            value={setup.specialGoodsProbability}
            onChange={(e) => setSpecialGoodsProbability(Number(e.target.value))}
          />
        </div>
        <div className="flex gap-2">
          <label className="text-white text-2xl w-80">시즌 Goods 확률(0 ~ 1)</label>
          <input
            className="p-2 rounded w-16"
            type="number"
            value={setup.seasonGoodsProbability}
            onChange={(e) => setSeasonGoodsProbability(Number(e.target.value))}
          />
        </div>
        <button className="bg-white text-black text-2xl px-8 py-2 mt-8 rounded-lg" onClick={() => navigate('/home')}>
          시작페이지로
        </button>
      </div>
    </PageWithHeader>
  );
}

export default Setup;
